<template>
  <div class="bodywhite">
    <div class="container">
      <div class="paddingtop80">
        <h1 class="fontcolor-mainblue">Lege deinen <br> Nutzername und <br> Kennwort fest.</h1>
        <form action="/action_page.php" class="margintop80">
          <input type="text" id="nutzername" name="nutzername" class="inputclassic backgroundcolor-beige fontcolor-tertiaryblue" placeholder="Nutzername">
          <input type="text" id="kennwort" name="kenwort" class="inputclassic backgroundcolor-beige fontcolor-tertiaryblue" placeholder="Kennwort">
        </form>
      </div>
    </div>

    <div class="stepnavigation backgroundcolor-beige">
      <div class="container">
        <button type="button" value="Submit" class="buttonXS backgroundcolor-white col-2 col-center center" @click="registrationusernamezuwelcome()">
          <img class="navicon:active" src="../assets/icons/arrow.svg">
        </button>
        <button type="button" value="Submit" class="buttonS fontcolor-white backgroundcolor-mainblue" @click="registrationusernamezuregistrationtime()">
          <p class="pbold display-inline fontcolor-white">Los geht's</p>
          <img class="display-inline" src="../assets/icons/arrow_white.svg" alt="">
        </button>
      </div>
    </div>

  </div>
</template>

<style scoped>


::placeholder {
  color: #7C8489;
  opacity: 1;
}

</style>

<script>
  import { mapActions } from 'vuex';
  import router from '../router';
  import FootNavigationVue from "../components/FootNavigation.vue";

  export default {
    name: 'registrationusername',
    components: {
      'foot-navigation' : FootNavigationVue,
    },
    methods: {
      ...mapActions(['loadUserData']),
      registrationusernamezuwelcome() {
        router.push({path: '/'})
      },
      registrationusernamezuregistrationtime() {
        router.push({path: 'registrationtime'})
      },
    }
  };
</script>
